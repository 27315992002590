import React from 'react';
import PropTypes from 'prop-types';

//HACK
import {
  BlogStyle,
} from '../../../../landing-gatsby/src/containers/SaasClassic/saasClassic.style';

import Box from 'reusecore/src/elements/Box';
import Text from 'reusecore/src/elements/Text';
import Heading from 'reusecore/src/elements/Heading';
import Button from 'reusecore/src/elements/Button';
import Image from 'reusecore/src/elements/Image';
import Container from 'common/src/components/UI/Container';

import InputHandler from './handlers'; // Code to handle user I/O

const BlogPost = ({
  rowCentered,
  row,
  contentWrapper,
  blogTitle,
  bodyText,

  className,
  thumbUrl,
  audio,
  title,
  date,
  author,
  excerpt,
  link,
}) => {
  // Add all classs to an array
  const addAllClasses = ['blog_post'];

  // className prop checking
  if (className) {
    addAllClasses.push(className);
  }
  
  let formattedDate = ""
  if (date) {
    const options1 = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    formattedDate = new Intl.DateTimeFormat('en-US', options1).format( new Date (date));
    // console.log({title, date, formattedDate})
  }


  return (
    <Container className="blog_post">
      {/* <BlogStyle/> */}
      <Box {...row}>
        <Box {...contentWrapper}>
          <div className={addAllClasses.join(' ')}>
            <Box {...row}>
              <Image
                src={thumbUrl}
                alt={title}
                style={{ borderRadius: '10px' }}
              />
            </Box>

            <Text content={audio} />
            {/*** AUDIO LINK (optional) ***/}
            {audio ? (
              <Box {...row}>
                <Button
                  title="PLAY"
                  onClick={async () => {
                    await InputHandler.playEpisode(audio);
                  }}
                />
              </Box>
            ) : (
              <React.Fragment></React.Fragment>
            )}

            <Box {...row}>
              <Heading {...blogTitle} content={title} style={{color:"white"}}/>
            </Box>
            <Box {...row}>
              <p> {formattedDate} </p>
            </Box>

            {/* <div>
              <h4 className="author">{author}</h4>
              <h5 className="date">{date}</h5>
            </div> */}

            <Box {...row}>
              <div // MUST BE DIV DUE TO REACT BUG
                //https://github.com/gatsbyjs/gatsby/issues/11108
                {...bodyText}
                dangerouslySetInnerHTML={{ __html: excerpt }}
                // dangerouslySetInnerHTML={{ __html: `<div> ${excerpt} </div>` }}
              />
            </Box>

            {/* <div className="content">
              <div
                className="excerpt"
                dangerouslySetInnerHTML={{ __html: excerpt }}
              />
            </div> */}

            {/* {link && <div className="learn_more">{link}</div>} */}
          </div>
        </Box>
      </Box>
    </Container>
  );
};

// BlogPost.propTypes = {
//   className: PropTypes.string,
//   thumbUrl: PropTypes.string,
//   title: PropTypes.string,
//   excerpt: PropTypes.string,
//   link: PropTypes.string,
// };

BlogPost.defaultProps = {
  rowCentered: {
    textAlign: 'center',
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    mt: '50px',
    mb: '50px',
  },
  contentWrapper: {
    width: ['100%', '100%', '75%', '75%', '75%'],
    mb: '40px',
  },
  blogTitle: {
    fontSize: ['24px', '32px', '40px', '42px', '46px'],
    fontWeight: '700',
    color: '#000',
    letterSpacing: '-0.025em',
    mb: ['20px', '25px', '25px', '25px', '25px'],
    lineHeight: '1.2',
    textAlign: 'center',
  },
  bodyText: {
    // fontSize: ['24px', '32px', '40px', '42px', '46px'],
    // fontFamily: 'Raleway',
    fontSize: ['24px'],
    fontWeight: '400',
    color: '#000',
    // letterSpacing: '-0.025em',
    mb: ['20px', '25px', '25px', '25px', '25px'],
    lineHeight: '1.5',
    textAlign: 'left',
  },
};

export default BlogPost;

import React, { Fragment } from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';

import { ThemeProvider } from 'styled-components';
import { Modal } from '@redq/reuse-modal';
import Sticky from 'react-stickynode';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import { saasClassicTheme } from 'common/src/theme/saasClassic';
import { ResetCSS } from 'common/src/assets/css/style';
import {
  GlobalStyle,
  BlogStyle,
  ContentWrapper,
} from '../containers/SaasClassic/saasClassic.style';

// import Box from 'reusecore/src/elements/Box';
import BlogPost from 'common/src/components/BlogPost';

import BannerSection from '../containers/SaasClassic/Banner';
import Navbar from '../containers/SaasClassic/Navbar';
// import ServiceSection from '../containers/SaasClassic/Service';
// import PricingSection from '../containers/SaasClassic/Pricing';
// import PartnerSection from '../containers/SaasClassic/Partner';
// import TrialSection from '../containers/SaasClassic/Trial';
// import FeatureSection from '../containers/SaasClassic/Feature';
// import UpdateScreen from '../containers/SaasClassic/UpdateScreen';
// import TestimonialSection from '../containers/SaasClassic/Testimonial';
import Newsletter from '../containers/SaasClassic/Newsletter';
import Footer from '../containers/SaasClassic/Footer';
import '@redq/reuse-modal/es/index.css';
import SEO from '../components/seo';

const BlogPostPage = ({ data }) => {
  const postQuery = useStaticQuery(graphql`
    query BlogPostData($path: String!) {
      markdownRemark(frontmatter: { path: { eq: $path } }) {
        html
        frontmatter {
          path
          title
          author
          date
          image
        }
      }
    }
  `);

  let post,
    html,
    frontmatter,
    path,
    title,
    author,
    date,
    image = null;

  if (data) {
    post = data.markdownRemark;
    html = post ? post.html : null;
    frontmatter = post ? post.frontmatter : null;
    path = frontmatter ? frontmatter.path : null;
    title = frontmatter ? frontmatter.title : null;
    author = frontmatter ? frontmatter.author : null;
    date = frontmatter ? frontmatter.date : null;
    image = frontmatter ? frontmatter.image : null;
  }
  // const post = data.markdownRemark;
  // const { html, frontmatter } = post;
  // const { title, author, date } = frontmatter;
  // console.log(title);
  // console.log(frontmatter);
  // console.log({ data, postQuery });

  return (
    <ThemeProvider theme={saasClassicTheme}>
      <Fragment>
        <SEO title={'podstacks | ' + title} />
        <Modal />
        {/* <ResetCSS /> */}
        {/* <GlobalStyle /> */}
        <BlogStyle />

        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>

          <BlogPost
            // className
            //thumbUrl="https://neoevolutions.s3-us-west-2.amazonaws.com/blog/marl-sunglasses.png"
            thumbUrl={image}
            title={title}
            author={author}
            date={date}
            excerpt={html}
            link="https://google.com/"
          />
  
          { //Check if COVID blog post newsletter
          ( new RegExp("(\/covid19\/)").test(path) ) ?
          ( <Newsletter 
            podcastSearch={true} 
            titleText="Calling all COVID-19 podcasters!"
            descText="Sign up and we'll transcribe your episodes for FREE."
            type="covid19"
          /> ): 
          // Regular blog post newsletter
          (<Newsletter podcastSearch={true} /> )}
          
          
          {/* <div dangerouslySetInnerHTML={{ __html: html }} /> */}
          {/* <Link to="/posts"> <h4>back to blog posts</h4></Link> */}
          {/* <BannerSection /> */}
          {/* <ServiceSection /> */}
          {/* <UpdateScreen /> */}
          {/* <FeatureSection /> */}
          {/* <PartnerSection /> */}
          {/* <PricingSection /> */}
          {/* <TestimonialSection /> */}
          {/* <TrialSection /> */}
          {/* <Newsletter /> */}
          {/* <Footer /> */}
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};

BlogPostPage.defaultProps = {
  background: {
    backgroundColor: 'blue',
    // backgroundImage: 'linear-gradient(-139deg, rgb(1, 137, 255) 0%, rgb(0, 53, 99) 100%)',
    // /* linear-gradient( -139deg, rgb(255, 75, 43) 0%, rgb(255, 65, 108) 100% ); */
    // backgroundSize: 'cover',
    // backgroundPosition: 'top center'
  },
};

export default BlogPostPage;

/**
 * Handler for input events for Blog Post
 *
 * This is where we call our custom API to manage Job requests
 */
// import fetch from 'cross-fetch';
// // import pms from 'pretty-ms'
// // import { format } from 'timeago.js';
// // import hd from 'humanize-duration';
// import queryString from 'query-string';

let InputHandler = {};
// const awstApi =
//   'https://sd7vtlwdci.execute-api.us-east-1.amazonaws.com/dev/awst/';
// const exportApi =
//   'https://h6cq69w7e1.execute-api.us-east-1.amazonaws.com/dev/export/';
// const jobsApi =
//   'https://175b2qwkd5.execute-api.us-east-1.amazonaws.com/dev/jobs/';
// const podcastsApi =
//   'https://0hl3744n88.execute-api.us-east-1.amazonaws.com/dev/podcasts/';

// const reqHeaders = {
//   'Content-Type': 'application/json',
// };

// AWST endpoints:
//   POST - https://sd7vtlwdci.execute-api.us-east-1.amazonaws.com/dev/awst/loadAudio/{jobId}
//   DELETE - https://sd7vtlwdci.execute-api.us-east-1.amazonaws.com/dev/awst/deleteAudio/{jobId}
//   POST - https://sd7vtlwdci.execute-api.us-east-1.amazonaws.com/dev/awst/start/{jobId}
//   POST - https://sd7vtlwdci.execute-api.us-east-1.amazonaws.com/dev/awst/saveRaw/{jobId}
// EXPORT endpoints:
//   POST - https://h6cq69w7e1.execute-api.us-east-1.amazonaws.com/dev/export/preExport/{jobId}
//   POST - https://h6cq69w7e1.execute-api.us-east-1.amazonaws.com/dev/export/toMarkdown/{jobId}
// JOBS endpoints:
//   POST - https://175b2qwkd5.execute-api.us-east-1.amazonaws.com/dev/jobs/create
//   GET - https://175b2qwkd5.execute-api.us-east-1.amazonaws.com/dev/jobs/{jobId}
//   PUT - https://175b2qwkd5.execute-api.us-east-1.amazonaws.com/dev/jobs/{jobId}
//   DELETE - https://175b2qwkd5.execute-api.us-east-1.amazonaws.com/dev/jobs/{jobId}
// PODCASTS endpoints:
//   GET - https://0hl3744n88.execute-api.us-east-1.amazonaws.com/dev/podcasts/search
//   GET - https://0hl3744n88.execute-api.us-east-1.amazonaws.com/dev/podcasts/{podcast_id}
//   GET - https://0hl3744n88.execute-api.us-east-1.amazonaws.com/dev/podcasts/episode/{episode_id}
//   GET - https://0hl3744n88.execute-api.us-east-1.amazonaws.com/dev/podcasts/episode/audioSupport/{episode_id}

// Play podcast episode in new window
const playEpisode = async audioUrl => {
  window.open(audioUrl);
};

export default InputHandler = {
  playEpisode,
};
